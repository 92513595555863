import React from "react";
import "./Tagline.css";
import "./TaglineServices.css";

const TaglineBlogs = () => {
  return (
    <div className="tagLine tagLineServices">
      <div className="herosection serviceshero">
        <section className="inst">
          <p className="ourservices">READ</p>
          <p>BLOGS</p>
        </section>
      </div>
    </div>
  );
};

export default TaglineBlogs;
