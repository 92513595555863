import React from "react";
import Helmet from "react-helmet";

const MetaData = ({ title, contents }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="keywords"
        content="Ariston Instruments, Tundla, Firozabad, Uttar Pradesh,Level Switch, Level Transmitter, PLC, Pressure Measurement, Pressure Switch, Pressure Transmitter, Process Indicator, Temperature Controller, Variable Frequency Drive, Level Measurement, Temperature Measurement, Flow Measurement, Automation, Measuring Instruments, Lab Instruments, Scientific Machine, Cold Chain Temperature Measurement, Pressure Transmitters, Vertex VT4826 PID Temperature Controller, Rosemount Differential Pressure Transmitter 3051CD, Tempu 04 Multi Use Temperature DataLogger, Envitec Oxygen Sensor OOM202, Danfoss Pressure Transmitter, Instrumentation & Control Equipments, Manufacturer from Firozabad"
      />
      <meta name="Description" content={contents} />
    </Helmet>
  );
};

export default MetaData;
