import pic1 from "../../Assets/AristonProducts/VibratingForkLevelSwitch.jpg";
import pic2 from "../../Assets/AristonProducts/OpticalLevelSwitch.jpg";
import pic3 from "../../Assets/AristonProducts/MagneticFloatLevelTransmitter.jpg";
import pic4 from "../../Assets/AristonProducts/SiemensProbeLuUltrasonicLevelTransmitter.jpg";
import pic5 from "../../Assets/AristonProducts/HydrostaticLevelTransmitter.jpg";
import pic6 from "../../Assets/AristonProducts/CapacitanceLevelTransmitter.jpg";
import pic7 from "../../Assets/AristonProducts/AllenBradleyPLC.jpg";
import pic8 from "../../Assets/AristonProducts/SIEMENSSIMATIC S7-300PLC.jpg";
import pic9 from "../../Assets/AristonProducts/GefranPressureSensor.jpg";
import pic10 from "../../Assets/AristonProducts/PRESSURETRANSMITTER.jpg";
import pic11 from "../../Assets/AristonProducts/WaterLevelSensor.jpg";
import pic12 from "../../Assets/AristonProducts/GemsPressureSensor.jpg";
import pic13 from "../../Assets/AristonProducts/Temperature-HumidityDuctSensor.jpg";
import pic14 from "../../Assets/AristonProducts/TPHHighpressure1000bar.jpg";
import pic15 from "../../Assets/AristonProducts/TEMPERATUREHUMIDITYDATALOGGER.jpg";
import pic16 from "../../Assets/AristonProducts/On-OffTemperatureController.jpg";
import pic17 from "../../Assets/AristonProducts/BronzeHoneywell PressureReducingValve.jpg";
import pic18 from "../../Assets/AristonProducts/Gefran KS SERIES Pressure Transducer.jpg";
import pic19 from "../../Assets/AristonProducts/Thermo Hygrometer.jpg";
import pic20 from "../../Assets/AristonProducts/Differential pressure transmitter.jpg";
import pic21 from "../../Assets/AristonProducts/Gefran Flush Diaphragm Pressure Transmitter.jpg";
import pic22 from "../../Assets/AristonProducts/Differential pressure switch.jpg";
import pic23 from "../../Assets/AristonProducts/Cup Counter Anemometer.jpg";
import pic24 from "../../Assets/AristonProducts/MBS 3000 Pressure Sensor.jpg";
import pic25 from "../../Assets/AristonProducts/Electronic Pressure Switch.jpg";
import pic26 from "../../Assets/AristonProducts/Digital Display Pressure Switch.jpg";
import pic27 from "../../Assets/AristonProducts/Barksdale Pressure Switch.jpg";
import pic28 from "../../Assets/AristonProducts/Rosemount Differential Pressure Transmitter.jpg";
import pic29 from "../../Assets/AristonProducts/Remote Seal Level Transmitter.jpg";
import pic30 from "../../Assets/AristonProducts/Differential Pressure Transmitters.jpg";
import pic31 from "../../Assets/AristonProducts/Compact Low Pressure OEM Pressure Transmitters.jpg";
import pic32 from "../../Assets/AristonProducts/Pressure Transmitter for General Industrial Purposes.jpg";
import pic33 from "../../Assets/AristonProducts/Smartline Gauge Pressure.jpg";
import pic34 from "../../Assets/AristonProducts/Heavy Duty Pressure Transducers.jpg";
import pic35 from "../../Assets/AristonProducts/Flame Proof Pressure Transmitter.jpg";
import pic36 from "../../Assets/AristonProducts/Smartline Differential Pressure.jpg";
import pic37 from "../../Assets/AristonProducts/Electr. Dual Pressure Switch.jpg";
import pic38 from "../../Assets/AristonProducts/Multi-Use PDF Temp & RH Data Logger.jpg";
import pic39 from "../../Assets/AristonProducts/FLOW METER.jpg";
import pic40 from "../../Assets/AristonProducts/Digital Pressure Indicator.jpg";
import pic41 from "../../Assets/AristonProducts/16 Channel Universal Scanner Logger.jpg";
import pic42 from "../../Assets/AristonProducts/Salinity Meter.jpg";
import pic43 from "../../Assets/AristonProducts/Fuji Pid Temperature Controller.jpg";
import pic44 from "../../Assets/AristonProducts/Yokogawa UT35A Temperature Controller.jpg";
import pic45 from "../../Assets/AristonProducts/DC1010 Honeywell Temperature Controller.jpg";
import pic46 from "../../Assets/AristonProducts/DC1040 Honeywell Temperature Controller.jpg";
import pic47 from "../../Assets/AristonProducts/Honeywell Udc Pid Temperature Controller.jpg";
import pic48 from "../../Assets/AristonProducts/UB Series Controller.jpg";
import pic49 from "../../Assets/AristonProducts/Temperature Controllers.jpg";
import pic50 from "../../Assets/AristonProducts/HYGRO METER.jpg";
import pic51 from "../../Assets/AristonProducts/Digital metal hardness tester.jpg";
import pic52 from "../../Assets/AristonProducts/SIEMENS Variable Frequency Drive.jpg";
import pic53 from "../../Assets/AristonProducts/Honeywell Smart Differential Pressure Transmitter.jpg";
import pic54 from "../../Assets/AristonProducts/Smart Pressure Transmitter.jpg";
import pic55 from "../../Assets/AristonProducts/Adhesion tester.jpg";
import pic56 from "../../Assets/AristonProducts/Digital DO Meter.jpg";
import pic57 from "../../Assets/AristonProducts/ORP Meter.jpg";
import pic58 from "../../Assets/AristonProducts/Pulse Oximeter.jpg";
import pic59 from "../../Assets/AristonProducts/TDS Meter.jpg";
import pic60 from "../../Assets/AristonProducts/Tds-Ec Meter.jpg";
import pic61 from "../../Assets/AristonProducts/4 in 1 Soil Moisture Meter.jpg";
import pic62 from "../../Assets/AristonProducts/Salinity Refractometer.jpg";
import pic63 from "../../Assets/AristonProducts/soil ph meter.jpg";
import pic64 from "../../Assets/AristonProducts/Conductivity Meter Digital.jpg";
import pic65 from "../../Assets/AristonProducts/Digital Colorimeter.jpg";
import pic66 from "../../Assets/AristonProducts/Lux Meter 50000 lux.jpg";
import pic67 from "../../Assets/AristonProducts/Probe Kitchen Thermometer.jpg";
import pic68 from "../../Assets/AristonProducts/probe thermometer.jpg";
import pic69 from "../../Assets/AristonProducts/Digital Vernier Caliper.jpg";
import pic70 from "../../Assets/AristonProducts/Eectronic Digital Timer.jpg";
import pic71 from "../../Assets/AristonProducts/Ph paper.jpg";
import pic72 from "../../Assets/AristonProducts/Thermo Hygrometer HTC 2.jpg";
import pic73 from "../../Assets/AristonProducts/food thermometer.jpg";
import pic74 from "../../Assets/AristonProducts/Elcometer coating thickness gauge.jpg";
import pic75 from "../../Assets/AristonProducts/Breath tester with printer.jpg";
import pic76 from "../../Assets/AristonProducts/Digital vernier caliper1.jpg";
import pic77 from "../../Assets/AristonProducts/Metal hardness tester.jpg";

export const LevelSwitch = [
  {
    id: 1,
    productTag: "Level Switch",
    productName: "Vibrating Fork Level Switch",
    Image: pic1,
    make: "",
    model: "",
    desc: "Backed by a team of adroit professionals, we are engrossed in supplying Vibrating Fork Level Switch that is used in industrial applications. It is used in pump protection, leak detection, and high and low alarm systems. It has got high build quality, resilience, and impressive design, therefore, it is preferred by our customers. Owing to high precision and reliable performance, the offered Vibrating Fork Level Switch ensures longevity and optimum performance. It can be purchased from us at a budget-friendly price.",
  },
  {
    id: 2,
    productTag: "Level Switch",
    productName: "Optical Level Switch",
    Image: pic2,
    make: "",
    model: "",
    desc: "We are actively engrossed in supplying Optical Level Switch that is used widely in industrial applications. It is the most suited in applications including run-dry pump protection, leak detection, and high and low alarm systems. It is used to protect important machinery and to detect the level of fluids in vehicles. Owing to its top-notch quality, effectiveness, and resilience, it is preferred by our customers. Our customers can approach us to purchase the Optical Level Switch at a budget-friendly price.",
  },
];

export const LevelTransmitter = [
  {
    id: 1,
    productTag: "Level Transmitter",
    productName: "Magnetic Float Level Transmitter",
    Image: pic3,
    make: "",
    model: "",
    desc: "We are instrumental in supplying the best in line Magnetic Float Level Transmitter that is used in a sealed tank. It is float-type measuring equipment having the capability of enduring chemical attacks, shear forces, and tough conditions. It is a durable and resilient product. Magnetic Float Level Transmitter is easy to use and maintain. Our customers can approach us to purchase the  Magnetic Float Level Transmitter at a budget-friendly price.",
  },
  {
    id: 2,
    productTag: "Level Transmitter",
    productName: "Siemens Probe Lu Ultrasonic Level Transmitter",
    Image: pic4,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 3,
    productTag: "Level Transmitter",
    productName: "Hydrostatic Level Transmitter",
    Image: pic5,
    make: "",
    model: "",
    desc: "We are actively engrossed in supplying the top-of-the-line Hydrostatic Level Transmitter that is popularly known among our customers owing to its superior quality and effectiveness. It is used to provide continuous level measurement over the range of the system. Owing to its effectiveness and efficient performance, it is preferred by our customers. Hydrostatic Level Transmitter is used in industrial applications. Our customers can approach us to purchase the level transmitter at a budget-friendly price.",
  },
  {
    id: 4,
    productTag: "Level Transmitter",
    productName: "Capacitance Level Transmitter",
    Image: pic6,
    make: "",
    model: "",
    desc: "Backed by a team of diligent professionals, we are engrossed in supplying the top-of-the-line Capacitance Level Transmitter that is popularly known among our customers owing to its superior design and unmatched quality. It provides continuous measurements over the range of the system. Owing to its in the fluid processing industry. Owing to its superior quality and unmatched performance, we are seeing a huge demand for the Capacitance Level Transmitter in the marketplace. Our customers can approach us to purchase the product at a budget-friendly price.",
  },
];
export const PLC = [
  {
    id: 1,
    productTag: "PLC",
    productName: "Allen Bradley PLC",
    Image: pic7,
    make: "",
    model: "",
    desc: "Allen Bradley PLC Module Modular Form, In one backplane there are 4 7 10 and 13 modules The choice of backplane is adjusted to the needs the more complex the system is made the more backplane used If more than 13 modules are needed you must use an additional backplane because each backplane is up to 13 modules",
  },
  {
    id: 2,
    productTag: "PLC",
    productName: "SIEMENS SIMATIC S7-300 PLC",
    Image: pic8,
    make: "",
    model: "",
    desc: "SIEMENS SIMATIC S7-300 PLC is used to expand the system centrally or to create decentralized structures. It is known for continuity and quality. It is a programmable logic controller that is easy to install and maintain. It has got high build quality, sturdy construction, and resilience, therefore, we are seeing a huge demand for the SIEMENS SIMATIC S7-300 PLC in the marketplace. Our customers can approach us to purchase the PLC at a budget-friendly price.",
  },
];
export const PressureMeasurement = [
  {
    id: 1,
    productTag: "Pressure Measurement",
    productName: "Gefran Pressure Sensor",
    Image: pic9,
    make: "",
    model: "",
    desc: "We are engrossed in supplying the top-of-the-line Gefran Pressure Sensor that is popularly known among our customers owing to its superior design and unmatched quality. It is dedicated to applications involving high process temperatures. The pressure sensor is specially designed for industrial applications. It is easy to operate and maintain. Owing to its superior design, high build quality, and resilience, it is preferred by our customers. Gefran Pressure Sensor can be purchased from us at a budget-friendly price.",
  },
  {
    id: 2,
    productTag: "Pressure Measurement",
    productName: "PRESSURE TRANSMITTER",
    Image: pic10,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 3,
    productTag: "Pressure Measurement",
    productName: "Water Level Sensor",
    Image: pic11,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 4,
    productTag: "Pressure Measurement",
    productName: "Gems Pressure Sensor",
    Image: pic12,
    make: "",
    model: "",
    desc: "We are actively engrossed in supplying Gems Pressure Sensor that is used in a range of applications It is used in a range of applications including vehicles robotics transportation semiconductor and general industries Owing to its reliable performance longevity and resilience it is preferred by our customers Gems Pressure Sensor does not degrade in quality or become inferior in the long run Our customers can approach us to purchase the pressure sensor at a budgetfriendly price",
  },
  {
    id: 5,
    productTag: "Pressure Measurement",
    productName: "Temperature-Humidity Duct Sensor",
    Image: pic13,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 6,
    productTag: "Pressure Measurement",
    productName: "TPH High pressure 1000bar mV V output",
    Image: pic14,
    make: "",
    model: "",
    desc: "Suitable for measuring static and dynamic pressure.",
  },
  {
    id: 7,
    productTag: "Pressure Measurement",
    productName: "TEMPERATURE HUMIDITY DATA LOGGER",
    Image: pic15,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 8,
    productTag: "Pressure Measurement",
    productName: "On-Off Temperature Controller",
    Image: pic16,
    make: "",
    model: "",
    desc: "SZ 7558P/ 7568P Alarm Controllers",
  },
  {
    id: 9,
    productTag: "Pressure Measurement",
    productName: "Bronze Honeywell Pressure Reducing Valve",
    Image: pic17,
    make: "",
    model: "",
    desc: "We have Honeywell Pressure Reducing Valve from 15 mm to 200 mm at a discounted prices.",
  },
  {
    id: 10,
    productTag: "Pressure Measurement",
    productName: "Gefran KS SERIES Pressure Transducer",
    Image: pic18,
    make: "",
    model: "",
    desc: "Owing to the expertise of our highly skilled professionals, we are offering an optimum quality assortment of Gefran Pressure Transmitter that usually acts as a transducer. The transmitter provided by us is manufactured with the usage of supreme quality components and innovative technology in complete compliance with market quality norms and policies. Moreover, we offer this transmitter at highly affordable rates to the clients within the promised time span.",
  },
  {
    id: 11,
    productTag: "Pressure Measurement",
    productName: "Thermo Hygrometer",
    Image: pic19,
    make: "",
    model: "",
    desc: "3 meter external outdoor sensor cord, Relative Humidity (20 % 90 %), Min/Max Memory for Temperature & Humidity Clock Function. C/F selectable, Foldable Stand & Wall mountable, LCD size : 41 mm x 60 mm, Unit size : 58 x 15 x 108mm, *Battery : AAA x 1pcs",
  },
  {
    id: 12,
    productTag: "Pressure Measurement",
    productName: "Differential pressure transmitter",
    Image: pic20,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 13,
    productTag: "Pressure Measurement",
    productName: "Gefran Flush Diaphragm Pressure Transmitter",
    Image: pic21,
    make: "",
    model: "",
    desc: "Flush fitting stainless steel measuring diaphragm Digital Autozero & Span function",
  },
  {
    id: 14,
    productTag: "Pressure Measurement",
    productName: "Differential pressure switch",
    Image: pic22,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 15,
    productTag: "Pressure Measurement",
    productName: "Cup Counter Anemometer",
    Image: pic23,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 16,
    productTag: "Pressure Measurement",
    productName: "MBS 3000 Pressure Sensor",
    Image: pic24,
    make: "",
    model: "",
    desc: "MBS 300 Pressure Sensor that we are offering is popularly known among our customers owing to its superior design and performance. It is used to control and monitor industrial processes. It can also be used to measure variables including pressure, speed, water level, and altitude. Owing to its resilience and longevity, it is preferred by our customers. MBS 300 Pressure Sensor does not degrade in quality or become inferior in the long run. It can be purchased from us at a budget-friendly price.",
  },
];
export const PressureSwitch = [
  {
    id: 1,
    productTag: "Pressure Switch",
    productName: "Electronic Pressure Switch",
    Image: pic25,
    make: "",
    model: "",
    desc: "We are instrumental in supplying the top-of-the-line Electronic Pressure Switch that is used to measure the change in inlet pressure. It is a digital device that boasts a variety of features including a switching function. It is easy to install and maintain. The pressure switch is capable of enduring impacts, chemical attacks, and heavy loads. It does not degrade in quality or become inferior in the long run. Our customers can approach us to purchase the Electronic Pressure Switch at a budget-friendly price.",
  },
  {
    id: 2,
    productTag: "Pressure Switch",
    productName: "Digital Display Pressure Switch",
    Image: pic26,
    make: "",
    model: "",
    desc: "Digital Display Pressure Switch is used to monitors the systems fluid pressure. It is an advanced industrial device designed and developed in our state-of-the-art production site making the best use of pioneering in-house tools and techniques. owing to its effectiveness, reliable performance, and accuracy, it is preferred by our customers. The pressure switch is a user-friendly device. It does not degrade in quality or become inferior in the long run. Our customers can approach us to purchase the  Digital Display Pressure Switch at a budget-friendly price.",
  },
  {
    id: 3,
    productTag: "Pressure Switch",
    productName: "Barksdale Pressure Switch",
    Image: pic27,
    make: "",
    model: "",
    desc: "Backed by a team of adroit professionals, we are engrossed in supplying Barksdale Pressure Switch that is used in fluid processing applications. It is an electrical switch that makes an electrical contact on either pressure rise of fall from a certain preset level. Owing to its precise design, reliable performance, and longevity, it is preferred by our customers. Barksdale Pressure Switch does not degrade in quality or become inferior in the long run. Our customers can approach us to purchase the Barksdale Pressure Switch at a budget-friendly price.",
  },
];
export const PressureTransmitter = [
  {
    id: 1,
    productTag: "Pressure Transmitter",
    productName: "Rosemount Differential Pressure Transmitter",
    Image: pic28,
    make: "",
    model: "",
    desc: "Rosemount Differential Pressure Transmitter is used to measure pressure before and after the fluid encounters an interruption. This instrument can also be used to measure liquid levels. It offers a range of methods to measure and monitor liquid levels. Owing to its effectiveness, premium quality, and resilience, it is preferred by our customers. Rosemount Differential Pressure Transmitter can be purchased from us at a budget-friendly price.",
  },
  {
    id: 2,
    productTag: "Pressure Transmitter",
    productName: "Remote Seal Level Transmitter",
    Image: pic29,
    make: "",
    model: "",
    desc: "Remote Seal Level Transmitter is equipped with pressure sensors as a sensing element to detect the level depth in a liquid. It is a highly precise and sensitive device. Owing to its longevity and high build quality, it is preferred by our customers. Remote Seal Level Transmitter can withstand tough conditions, impacts, and chemical attacks, therefore, this is sure to last for an extended period of time. Our customers can approach us to purchase the level transmitter at a budget-friendly price.",
  },
  {
    id: 3,
    productTag: "Pressure Transmitter",
    productName: "Differential Pressure Transmitters",
    Image: pic30,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 4,
    productTag: "Pressure Transmitter",
    productName: "Compact Low Pressure OEM Pressure Transmitters",
    Image: pic31,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 5,
    productTag: "Pressure Transmitter",
    productName: "Pressure Transmitter for General Industrial Purposes",
    Image: pic32,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 6,
    productTag: "Pressure Transmitter",
    productName: "Smartline Gauge Pressure",
    Image: pic33,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 7,
    productTag: "Pressure Transmitter",
    productName: "Heavy Duty Pressure Transducers",
    Image: pic34,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 8,
    productTag: "Pressure Transmitter",
    productName: "Flame Proof Pressure Transmitter",
    Image: pic35,
    make: "",
    model: "",
    desc: "Flame Proof Pressure Transmitter is unmatched in terms of design and quality. It has got high-end and high-performance designs. It is mainly used in industries to measure pressure in liquids, fluids, or gases. It is commonly used to measure the pressure inside industrial machinery. Owing to its high precision and top-notch quality, it is preferred by our customers. Flame Proof Pressure Transmitter does not degrade in quality or become inferior in the long run. Our customers can approach us to purchase the pressure transmitter at a budget-friendly price.",
  },
  {
    id: 9,
    productTag: "Pressure Transmitter",
    productName: "Smartline Differential Pressure",
    Image: pic36,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 10,
    productTag: "Pressure Transmitter",
    productName: "Electr. Dual Pressure Switch",
    Image: pic37,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 11,
    productTag: "Pressure Transmitter",
    productName: "Multi-Use PDF Temp & RH Data Logger",
    Image: pic38,
    make: "",
    model: "",
    desc: "",
  },
];
export const ProcessIndicator = [
  {
    id: 1,
    productTag: "Process Indicator",
    productName: "FLOW METER",
    Image: pic39,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 2,
    productTag: "Process Indicator",
    productName: "Digital Pressure Indicator",
    Image: pic40,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 3,
    productTag: "Process Indicator",
    productName: "16 Channel Universal Scanner Logger",
    Image: pic41,
    make: "",
    model: "",
    desc: "",
  },
];
export const TemperatureController = [
  {
    id: 1,
    productTag: "Temperature Controller",
    productName: "Salinity Meter",
    Image: pic42,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 2,
    productTag: "Temperature Controller",
    productName: "Fuji Pid Temperature Controller",
    Image: pic43,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 3,
    productTag: "Temperature Controller",
    productName: "Yokogawa UT35A Temperature Controller",
    Image: pic44,
    make: "",
    model: "",
    desc: "Yokogawa UT35A Temperature Controller that we are offering is a precision device designed to control a heater or other equipment by comparing a sensor signal with a set point. Owing to its superior design and reliable performance, it is preferred by our customers. Yokogawa UT35A Temperature Controller is easy to install and maintain. It does not degrade in quality or become inferior in the long run. Our customers can approach us to purchase the controller at a budget-friendly price.",
  },
  {
    id: 4,
    productTag: "Temperature Controller",
    productName: "DC1010 Honeywell Temperature Controller",
    Image: pic45,
    make: "",
    model: "",
    desc: "We are instrumental in supplying the top-of-the-line DC1010 Honeywell Temperature Controller that is popularly known among our customers owing to its superior design and reliable performance. This is a precision device that can endure heat, chemical attacks, and tough conditions. It does not degrade in quality or become inferior in the long run. Our customers can approach us to purchase the DC1010 Honeywell Temperature Controller at a budget-friendly price.",
  },
  {
    id: 5,
    productTag: "Temperature Controller",
    productName: "DC1040 Honeywell Temperature Controller",
    Image: pic46,
    make: "",
    model: "",
    desc: "DC1040 Honeywell Temperature Controller that we are offering is popularly known among our customers owing to its capability of withstanding heat, moisture, abrasive chemicals, and tough conditions. The temperature controller is a high-end device designed for fluid processing applications. It is an advanced industrial machine. DC1040 Honeywell Temperature Controller is easy to install and maintain. It can be purchased from us at a reasonable price.",
  },
  {
    id: 6,
    productTag: "Temperature Controller",
    productName: "Honeywell Udc Pid Temperature Controller",
    Image: pic47,
    make: "",
    model: "",
    desc: "We are actively engrossed in supplying the top-of-the-line Honeywell Udc Pid Temperature Controller that is popularly known among our customers owing to its superior design and unmatched quality. The controller has got high operational efficiency and reliability. It has got the capability of withstanding tough conditions including heat, chemical attacks, and shear forces. Therefore, this is sure to last for an extended period of time. Our customers can approach us to purchase the Honeywell Udc Pid Temperature Controller at a budget-friendly price.",
  },
  {
    id: 7,
    productTag: "Temperature Controller",
    productName: "UB Series Controller",
    Image: pic48,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 8,
    productTag: "Temperature Controller",
    productName: "Temperature ControllersTemperature Controllers",
    Image: pic49,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 9,
    productTag: "Temperature Controller",
    productName: "HYGRO METER",
    Image: pic50,
    make: "",
    model: "",
    desc: "This Gadget is generally used for an incubator to monitor Realtime temperature and Humidity with daily Calender clock and alarm system on one single large LCD screen display features oneinch tall digital numbers which can be seen from 50 feet away It can also add an aesthetic look to your laboratory work station home or office. Three adjustment push of buttons are given along with max and min temperature readings perfect for home or office features an economical way to monitor indoor or outdoor temperature and humidity measures temperatures as low as 58 Fahrenheit  50 c  and up to 158 Fahrenheit  70c  watch has both 12 24 hour format display temperature humidity  time simultaneously memory of maxmin humidity  temperature readings large character LCD display easy to read lower power consumption. The Gadget has an option to switch the Unit of temperature between Celcius and Fahrenheit by just pressing a pushbutton given on the backside",
  },
  {
    id: 10,
    productTag: "Temperature Controller",
    productName: "Digital metal hardness tester",
    Image: pic51,
    make: "",
    model: "",
    desc: "Technical specificationsStandard Impact DeviceD integrated Hardness scalesHLD, HB, HRC, HRA, HV, HS Measuring range/materia See table above Accuracy+6HLD(760 +30HLD) Memory99 average readings OutputRS232 to printer Min. Surface Roughness of Work 1.6u (Ra) piece Max. Workpiece Hardness 900HLD Min. radius of Workpiece Rmin = 50mm (convex/concave)(with support ring Rmin= 10mm) Min. Workpiece weight2-5kg on a stable support0.05-2kg with compact coupling Min. Workpiece thickness 5mm coupled Min. The thickness of hardened | 0.8mm layers Indentation depthImpact Devises data (See page 8) Continuous working time8 h PowerRechargeable Li-Polymer batteries Operating temperature0-40 C Overall dimensions155x24x55mm Weight180 g",
  },
];
export const VariableFrequencyDrive = [
  {
    id: 1,
    productTag: "Variable Frequency Drive",
    productName: "SIEMENS Variable Frequency Drive",
    Image: pic52,
    make: "",
    model: "",
    desc: "Backed by a team of adroit professionals, we are engrossed in supplying SIEMENS Variable Frequency Drive that is popularly known among our customers owing to its top-notch quality and reliable performance. It is used in industrial applications. Owing to its high build quality, resilience, and reliable performance, we are seeing a huge demand for the SIEMENS Variable Frequency Drive in the marketplace. Our customers can approach us to purchase the variable frequency drive at a budget-friendly price.",
  },
];
export const PressureTransmitters = [
  {
    id: 1,
    productTag: "Pressure Transmitters",
    productName: "Honeywell Smart Differential Pressure Transmitter",
    Image: pic53,
    make: "",
    model: "",
    desc: "Honeywell Smart Differential Pressure Transmitter is an extremely sensitive and accurate device. It is suitable to use for varied critical applications. The offered pressure transmitter is capable of withstanding chemical attacks, shear forces, and tough conditions. It does not degrade in quality or become inferior in the long run. It has got the capability of withstanding impacts, tough climate conditions, and shear forces, therefore, this is sure to last for an extended period of time.",
  },
  {
    id: 2,
    productTag: "Pressure Transmitters",
    productName: "Smart Pressure Transmitter",
    Image: pic54,
    make: "",
    model: "",
    desc: "Smart Pressure Transmitter that we are offering is a precision device designed for industrial applications. It is the most suited in the measurement of a range of parameters including density, level, the pressure of the liquid, among others. The device can be used to convert current signal output. Smart Pressure Transmitter is a durable and resilient device. Having the capability of withstanding chemical attacks, and heavy pressure, it is sure to last for an extended period of time.",
  },
  {
    id: 3,
    productTag: "Pressure Transmitters",
    productName: "Adhesion tester",
    Image: pic55,
    make: "",
    model: "",
    desc: "IntroductionThe portable, hand-operated PosiTest Pull-Off Adhesion Tester measures the force required to pull a specified test diameter of coating away from its substrate using hydraulic pressure. The pressure is displayed on a digital LCD and represents the coating's strength of adhesion to the substrate. In accordance with ASTM D4541, D7234, ISO 4624, and others, the PosiTest evaluates the adhesion (pull-off strength) of a coating by determining the greatest tensile pull-off force that it can bear before detaching. Breaking points, demonstrated by fractured surfaces, occur along the weakest plane within the system consisting of the dolly, adhesive, coating layers, and substrate.",
  },
];
export const LevelMeasurement = [];
export const TemperatureMeasurement = [];
export const FlowMeasurement = [];
export const Automation = [];
export const MeasuringInstruments = [
  {
    id: 1,
    productTag: "Measuring Instruments",
    productName: "Digital DO Meter",
    Image: pic56,
    make: "",
    model: "",
    desc: "Designed in ABS Plastic to provide the rugged portability matched in fields with the accuracy demanded in the laboratory. A handy carrying case holds Gold/Silver Amperometric DO   Probe and   Set of DO Membranes   needed in the field operation. The instrument measures DO of any solution in the fields, laboratory or plant.   Features Highly Stable and Accurate DO measurements Digit LCD Display ABS Plastic Body Rugged Portability Accessories Gold/Silver Amperometric DO   Probe Set of DO Membranes Dust Cover Operation Manual",
  },
  {
    id: 2,
    productTag: "Measuring Instruments",
    productName: "ORP Meter",
    Image: pic57,
    make: "",
    model: "",
    desc: "Oxidation-Reduction Potential ORP is an abbreviation that represents the oxidation potential of the solution. ORP value is a measure of the ability of the redox solution, the unit is mV. ORP meter test solution redox potential of special equipment. The instrument enclosure waterproof design, waterproof rating of IP55. The electrode can be replaced. With data lock function to facilitate the measurement and recording of data stored Negative potential (O.R.P) pen. The introduction of advanced technology and imported components, greatly reducing the volume, reduce costs, maintain the original quality, so the use of ORP gained popularity. Suitable for functional energy water purifier and negative potential energy cups and other needs of the determination of the value of having a negative potential.",
  },
  {
    id: 3,
    productTag: "Measuring Instruments",
    productName: "Pulse Oximeter",
    Image: pic58,
    make: "",
    model: "",
    desc: "Suitable for used in family, hospital and physical care in sport.",
  },
  {
    id: 4,
    productTag: "Measuring Instruments",
    productName: "TDS Meter",
    Image: pic59,
    make: "",
    model: "",
    desc: "Ideal for commercial use. Includes a carrying case with belt clip. Highly efficient and accurate due to its advanced microprocessor technology. Hold Function: saves measurements for convenient reading and recording. Auto-off function: the meter shuts off automatically after 10 minutes of non-use to conserve batteries. Built-in digital thermometer. Power source: 2 x 1.5V button cell batteries. Battery life: 1000 hours of usage.",
  },
  {
    id: 5,
    productTag: "Measuring Instruments",
    productName: "Tds-Ec Meter",
    Image: pic60,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 6,
    productTag: "Measuring Instruments",
    productName: "4 in 1 Soil Moisture Meter",
    Image: pic61,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 7,
    productTag: "Measuring Instruments",
    productName: "Salinity Refractometer",
    Image: pic62,
    make: "",
    model: "",
    desc: "Salinity Refractometer is designed for testing the concentration of salt water and brine and used for quality control in research and clinical laboratories and marine industry. Its scale provides a direct reading of the specific gravity and concentration (part per Thousand) of salt in water. One scale checks the NaCl levels with the range of 0-100 ppt (with 1 ppt scale divisions) and the other scale gauges Specific Gravity with a range of 1.000 to 1.070 (0.001 accuracy). Both enables the direct determination of salinity in water that contains dissolved salt and little or no other dissolved solids. It is suitable for a marine saltwater tank.",
  },
  {
    id: 8,
    productTag: "Measuring Instruments",
    productName: "Soil PH Meter",
    Image: pic63,
    make: "",
    model: "",
    desc: "Non-electronic 3 in 1 multifunctional soil ph moisture meter tester/ph soil meter But Ideal and necessary tool for gardeners, vegetable and fruit growers, and all those who plant trees and grass. You can take good care of your plants.",
  },
  {
    id: 9,
    productTag: "Measuring Instruments",
    productName: "Conductivity Meter Digital",
    Image: pic64,
    make: "",
    model: "",
    desc: "Common laboratory conductivity meters employ a potentiometric method and four electrodes. Often, the electrodes are cylindrical and arranged concentrically. The electrodes are usually made of platinum metal. An alternating current is applied to the outer pair of the electrodes.",
  },
  {
    id: 10,
    productTag: "Measuring Instruments",
    productName: "Digital Colorimeter",
    Image: pic65,
    make: "",
    model: "",
    desc: "Accurate, direct readout of absorbance, Rugged, Reliable & easy to operate, Long life photo diode detector, 1 ml sample size, Mains operated.",
  },
  {
    id: 11,
    productTag: "Measuring Instruments",
    productName: "Lux Meter 50000 lux",
    Image: pic66,
    make: "",
    model: "",
    desc: "Lux Meter Illuminance (Luminosity) of an object is the luminous flux per unit time is within the acceptable face to face, using accepted flux per unit area is represented, expressed in units of lux (Lux, lx), namely. 1 lux is equal to one lumen (lumen, lm) luminous flux uniformly distributed on the light intensity 1m2 area.",
  },
  {
    id: 12,
    productTag: "Measuring Instruments",
    productName: "Probe Kitchen Thermometer",
    Image: pic67,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 13,
    productTag: "Measuring Instruments",
    productName: "Probe Thermometer",
    Image: pic68,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 14,
    productTag: "Measuring Instruments",
    productName: "Digital Vernier Caliper",
    Image: pic69,
    make: "",
    model: "",
    desc: "Digital calipers are the most frequently used calipers for inner, outer, depth and step measurements.  Range 0-150mm",
  },
  {
    id: 15,
    productTag: "Measuring Instruments",
    productName: "Eectronic Digital Timer",
    Image: pic70,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 16,
    productTag: "Measuring Instruments",
    productName: "Ph paper",
    Image: pic71,
    make: "",
    model: "",
    desc: "1) There is a strong correlation between the pH of the bodys internal environment and pH paper help you to maintain good healthy body by testing many usual everyday substances including moisturized soap lemon juice milk liquid detergent moisturized soil saliva urine sweat etc.  2) The test strip colour changes must be matched with the pH scale colour chart within about 15 seconds.  Suitable For pH test strips testing for urine saliva amniotic fluid drinking water pool hot tub hydroponics aquariums food coffee potassium hydroxide and many more. 3) The scale reads from 1 to 141  3 indicates a very acidic substance4  6 indicates an acidic substance7 indicates neutral8  11 indicates alkalinity Note only for strong acid and base It will not work on water.",
  },
  {
    id: 17,
    productTag: "Measuring Instruments",
    productName: "Thermo Hygrometer HTC 2",
    Image: pic72,
    make: "",
    model: "",
    desc: "range- -10 to 70 degree, CLOCK, alarm",
  },
  {
    id: 18,
    productTag: "Measuring Instruments",
    productName: "Food Thermometer",
    Image: pic73,
    make: "",
    model: "",
    desc: "This TP101 electronic Digital Food thermometer is made in pen-type structure for convenient and portable use and made of stainless steel probe which is safer and more durable. Importantly it is available to various measured subjects including liquid, paste, oil, Food Thermometer processing, rubber and plastics processing and so on at a wide range of -50, +300",
  },
  {
    id: 19,
    productTag: "Measuring Instruments",
    productName: "Elcometer coating thickness gauge",
    Image: pic74,
    make: "",
    model: "",
    desc: "",
  },
  {
    id: 20,
    productTag: "Measuring Instruments",
    productName: "Breath tester with printer",
    Image: pic75,
    make: "",
    model: "",
    desc: "Breath Analyzer with built-in printer, memory, GPS, computer interface & touch screen",
  },
];
export const LabInstruments = [
  {
    id: 1,
    productTag: "Lab Instruments",
    productName: "Digital vernier caliper",
    Image: pic76,
    make: "",
    model: "",
    desc: "Electronic vernier caliper: 150 mm or 6 inch, made from hardened stainless steel, Measuring Range: 0 to 150 mm or 0 - 6 inch, Resolution 0.01mm or 0.0005 inch: Accuracy +/- 0.03 mm, Buttons (3) inch or mm On or Off, zero",
  },
  {
    id: 2,
    productTag: "Lab Instruments",
    productName: "Metal hardness tester",
    Image: pic77,
    make: "",
    model: "",
    desc: "Converts to all common hardness scales(HV ,HB,HRC,HRB,HRA,HS).",
  },
];
export const ScientificMachine = [];
export const ColdChainTemperatureMeasurement = [];
