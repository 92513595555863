import React from "react";
import "./NotFound.css";
import Navbar from "../../Utility/Navbar/Navbar";
import HeaderWave from "../../Utility/Wave/HeaderWave";
import Footer from "../../Utility/Footer/Footer";
import { Link } from "react-router-dom";
import BackHome from "../../Utility/Button/BackHome";

const NotFound = () => {
  return (
    <div className="notFound">
      <Navbar />
      <HeaderWave />
      <section className="aboutHeadingSection notTag">
        <p>404 Page Not Found</p>
        <span >
          It seems like we couldn't find the page you were looking for
        </span>
      </section>
      <section className="aboutHeadingSection notTag1">
        <Link to={"/"}>
          <BackHome />
        </Link>
      </section>

      <Footer />
    </div>
  );
};

export default NotFound;
