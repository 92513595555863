import React from "react";
import { useNavigate } from "react-router-dom";
import "./PopupContactForm.css";

const PopupContactForm = () => {
  //Declaring The State Variables and Input Handler Functions
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
  };
  const messageHandler = (e) => {
    setMessage(e.target.value);
  };

  //add the function for handling submission
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const response = await fetch("https://formspree.io/f/mpzeypaj", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, phone, message }),
    });

    if (response.ok) {
      navigate("/products");
      setButtonLoading(false);
    } else {
      alert("Failed to submit form");
    }
  };

  const backhandle = () => {
    navigate("/products");
  };

  return (
    <div className="card777">
      <div class="card786">
        <span class="title">Leave Your Inquiry</span>
        <form class="form" onSubmit={handleSubmit}>
          <div class="group">
            <input
              placeholder=""
              type="text"
              required
              value={name}
              onChange={nameHandler}
            />
            <label for="name">Name</label>
          </div>
          <div class="group">
            <input
              placeholder=""
              type="email"
              id="email"
              value={email}
              onChange={emailHandler}
              required
            />
            <label for="email">Email</label>
          </div>
          <div class="group">
            <input
              placeholder=""
              type="tel"
              id="phone"
              value={phone}
              onChange={phoneHandler}
              required
            />
            <label for="email">Phone</label>
          </div>
          <div class="group">
            <textarea
              placeholder=""
              id="comment"
              value={message}
              onChange={messageHandler}
              rows="5"
              required
            ></textarea>
            <label for="comment">Message</label>
          </div>
          {buttonLoading ? (
            <p>Loading....</p>
          ) : (
            <button type="submit">Submit</button>
          )}
        </form>
        <button className="backBTN" onClick={backhandle}>
          Close
        </button>
      </div>
    </div>
  );
};

export default PopupContactForm;
