import React from "react";
import "./Tagline.css";
import "./TaglineServices.css";

const TaglineProducts = () => {
  return (
    <div className="tagLine tagLineServices">
      <div className="herosection serviceshero">
        <section className="inst">
          <p className="ourservices">EXPLORE</p>
          <p>PRODUCTS</p>
        </section>
      </div>
    </div>
  );
};

export default TaglineProducts;
