import React from "react";
import "./Tagline.css";
import "./TaglineServices.css";
import "./aboutpp.css";
import "./contactTagline.css";

const TaglineContact = () => {
  return (
    <div className="tagLine tagLineServices tagLineAbout">
      <div className="herosection serviceshero">
        <section className="inst aboutpp abouttaglinkk contacttagline">
          <p>CONTACT US</p>
        </section>
      </div>
    </div>
  );
};

export default TaglineContact;
