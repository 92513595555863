import React, { Fragment } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <Fragment>
      <div className="nav">
        <input type="checkbox" id="nav-check" />
        <div className="nav-header">
          <div className="nav-title">ARISTON INSTRUMENTS</div>
        </div>
        <div className="nav-btn">
          <label for="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <div className="nav-links">
          <Link className="linknav" to={"/"}>Home</Link>
          <Link className="linknav" to={"/services"}>Services</Link>
          <Link className="linknav" to={"/products"}>Products</Link>
          <Link className="linknav" to={"/blog"}>Blog</Link>
          <Link className="linknav" to={"/about"}>About</Link>
          <Link className="linknav" to={"/contact"}>Contact</Link>
        </div>
      </div>
    </Fragment>
  );
};

export default Navbar;
