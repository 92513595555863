import React from "react";
import "./Home.css";
import Navbar from "../../Utility/Navbar/Navbar";
import HeaderWave from "../../Utility/Wave/HeaderWave";
import Tagline from "../../Utility/Tagline/Tagline";
import Footer from "../../Utility/Footer/Footer";
import ScrollBTN from "../../Utility/ScrollBTN/ScrollBTN";
import Buttons from "../../Utility/Button/Buttons";
import AristonLogo from "../../Assets/AristonLogo.png";
import AboutBTN from "../../Utility/Button/AboutBTN";
import FooterWave from "../../Utility/Wave/FooterWave";
import ContactDetail from "../../Utility/ContactDetail/ContactDetail";
import ARISTON from "../../Assets/ARISTON.png";
import TeamMembers from "../../Utility/TeamMembers/TeamMembers";
import TextMe from "../../Utility/TextMe/TextMe";
import SocialMediaBTN from "../../Utility/SocialMediaBTN/SocialMediaBTN";
import AnimationHome from "../../Utility/AnimationHome/AnimationHome";
import { Link } from "react-router-dom";
import MetaData from "../../Utility/MetaData";

const Home = () => {
  const services = [
    {
      heading: "Pressure Instruments",
      desc: "Instruments used to measure and display pressure mechanically are called pressure gauges, vacuum gauges or compound gauges (vacuum & pressure).",
    },
    {
      heading: "Level Instruments",
      desc: "Level instruments are a class of instruments designed to display and/or transmit a signal that corresponds to the level of the material that is being measured within a containing vessel.",
    },
    {
      heading: "Temperature Instruments",
      desc: "Temperature measurement (also known as thermometry) describes the process of measuring a current local temperature for immediate or later evaluation.",
    },
    {
      heading: "Measurement and Accuracy Instruments",
      desc: "The accuracy of instrumentation is determined by the difference of a measured value compared to its actual (true) value.",
    },
  ];
  return (
    <div className="home">
       <MetaData title="Ariston Instruments" contents={"Ariston Instruments is one such well-established and experienced organization which has been engaged in the process industry since 2021. We provide process industrial instrumentation for controlling, measuring and analyzing physical quantities using various types of interconnected process control instruments."}/>
      <Navbar />
      <HeaderWave />
      <ScrollBTN />
      <Tagline />

      {/* ============ Container ============ */}
      <div className="container">
        {/* ============ Services ============ */}
        <div className="span1 span"></div>
        <div className="serviceSection innerContainer">
          <div className="leftService">
            <div className="serviceDiv1">
              <p className="title">SERVICES</p>
              <h1>We Tailor Your Services Package To Suit Your Requirements</h1>
            </div>
            <div className="serviceDiv2">
              <p>
                Ariston Instruments is one such well-established and experienced
                organization which has been engaged in the process industry
                since 2021. We provide process industrial instrumentation for
                controlling, measuring and analyzing physical quantities using
                various types of interconnected process control instruments.
                Various types of instrumentation are used to measure variables
                including temperature, gas and fluid flow rates, fluid levels,
                pressure, Gems Pressure Sensor, Gefran Pressure Sensor and pH in
                production and manufacturing settings. These instrumentation and
                process control products include flow, pressure & level sensors,
                detectors and analyzers, controllers, monitoring systems, and
                much more.
              </p>
            </div>
            <Link to={"/services"} className="serviceDiv3">
              <Buttons />
            </Link>
          </div>
          <div className="rightService">
            {services.map((items) => {
              return (
                <div className="serviceDiv4">
                  <div className="detailContent">
                    <h3>{items.heading}</h3>
                    <p>{items.desc}</p>
                  </div>
                  <div className="icon"></div>
                </div>
              );
            })}
          </div>
        </div>
        {/* ============ Testimonials Team Members ============ */}
        <div className="span3 span"></div>
        <div className="testimonialSection innerContainer">
          <h2 className="teammember">Team Members</h2>
          <div className="span1 span"></div>
          <TeamMembers />
        </div>
        {/* ============ About Us ============ */}
        <div className="span3 span"></div>
        <div className="aboutSection innerContainer">
          <div className="leftAbout">
            <div className="imageDivAbout">
              <img src={AristonLogo} alt={"ariston instruments logo"} />
            </div>
          </div>
          <div className="rightAbout">
            <div className="aboutDiv1">
              <p className="title">ABOUT US</p>
              <h1>About Ariston Instruments</h1>
            </div>
            <div className="aboutDiv2">
              <p>
                Established in the year 2021 at Tundla (Uttar Pradesh),
                We,“Ariston Instruments” are one of the leading Retail Trader of
                a premium range of Pressure Measurement, Ac Drive & Temperature
                Controller, many more.
              </p>
              <p>
                With the support and guidance of our mentor Mr. Sachin Kumar, we
                have created a niche for ourselves in the market and
                successfully established beneficial business ties with the most
                reputed vendors and clients in the industry.
              </p>
            </div>
            <Link to={"/about"} className="aboutDiv3">
              <AboutBTN />
            </Link>
          </div>
        </div>
        {/* ============ Contact Details ============ */}
        <div className="span3 span"></div>
        <div className="contactSection innerContainer">
          <h2>Keep in Touch</h2>
          <div className="span1 span"></div>
          <TextMe />
          <SocialMediaBTN />
        </div>
      </div>
      <section className="logoSection">
        <div className="divLogoSection">
          <img src={ARISTON} alt="ARISTON LOGO" />
        </div>
      </section>
      <section className="contactDetailSection">
        <ContactDetail />
      </section>
      <section className="animationSection">
        <AnimationHome />
      </section>

      <FooterWave /> 
      <Footer />
    </div>
  );
};

export default Home;
