import React from "react";
import "./Tagline.css";
import "./TaglineServices.css";
import "./aboutpp.css";

const TaglineAbout = () => {
  return (
    <div className="tagLine tagLineServices tagLineAbout">
      <div className="herosection serviceshero">
        <section className="inst aboutpp abouttaglinkk">
          <p>ABOUT US</p>
        </section>
      </div>
    </div>
  );
};

export default TaglineAbout;
