import React from "react";
import "./Services.css";
import Navbar from "../../Utility/Navbar/Navbar";
import HeaderWave from "../../Utility/Wave/HeaderWave";
import TaglineServices from "../../Utility/Tagline/TaglineServices";
import Footer from "../../Utility/Footer/Footer";
import ServicesCard from "../../Utility/ServicesCard/ServicesCard";
import IndustryServe from "../../Utility/IndustryServe/IndustryServe";
import OurClient from "../../Utility/OurClient/OurClient";
import FooterWave from "../../Utility/Wave/FooterWave";
import ContactDetail from "../../Utility/ContactDetail/ContactDetail";
import ARISTON from "../../Assets/ARISTON.png";
import MetaData from "../../Utility/MetaData";

const Services = () => {
  return (
    <div className="services">
      <MetaData
        title="Services"
        contents={
          "Ariston Instruments is one such well-established and experienced organization which has been engaged in the process industry since 2021. We provide process industrial instrumentation for controlling, measuring and analyzing physical quantities using various types of interconnected process control instruments. Various types of instrumentation are used to measure variables including temperature, gas and fluid flow rates, fluid levels, pressure, Gems Pressure Sensor, Gefran Pressure Sensor and pH in production and manufacturing settings. These instrumentation and process control products include flow, pressure & level sensors, detectors and analyzers, controllers, monitoring systems, and much more."
        }
      />
      <Navbar />
      <HeaderWave />
      <TaglineServices />
      {/* ============ Container ============ */}
      <div className="container servicescontainer">
        <ServicesCard />
        <h1 className="industry">Industries We Serve</h1>
        <IndustryServe />
        <h1 className="industry ourclient">Our Trusted Partners</h1>
        <OurClient />
      </div>
      <section className="aboutHeadingSection servicesHeadingSection">
        <p>We Tailor Your Services Package To Suit Your Requirements</p>
      </section>
      <section className="logoSection">
        <div className="divLogoSection">
          <img src={ARISTON} alt="ARISTON LOGO" />
        </div>
      </section>
      <section className="contactDetailSection">
        <ContactDetail />
      </section>
      <FooterWave />
      <Footer />
    </div>
  );
};

export default Services;
