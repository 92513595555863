import React, { useEffect, useState } from "react";
import "./Products.css";
import Navbar from "../../Utility/Navbar/Navbar";
import HeaderWave from "../../Utility/Wave/HeaderWave";
import TaglineProducts from "../../Utility/Tagline/TaglineProducts";
import Footer from "../../Utility/Footer/Footer";
import ProductLists from "../../Utility/ProductLists/ProductLists";
import {
  LevelSwitch,
  LevelTransmitter,
  PLC,
  PressureMeasurement,
  PressureSwitch,
  PressureTransmitter,
  ProcessIndicator,
  TemperatureController,
  VariableFrequencyDrive,
  LevelMeasurement,
  TemperatureMeasurement,
  FlowMeasurement,
  Automation,
  MeasuringInstruments,
  LabInstruments,
  ScientificMachine,
  ColdChainTemperatureMeasurement,
  PressureTransmitters,
} from "./Data";
import { useNavigate } from "react-router-dom";
import MetaData from "../../Utility/MetaData";

const Products = () => {
  const [selected, setSelected] = useState(1);
  const [data, setData] = useState([]);

  const list = [
    {
      id: 1,
      title: "Level Switch",
    },
    {
      id: 2,
      title: "Level Transmitter",
    },
    {
      id: 3,
      title: "PLC",
    },
    {
      id: 4,
      title: "Pressure Measurement",
    },
    {
      id: 5,
      title: "Pressure Switch",
    },
    {
      id: 6,
      title: "Pressure Transmitter",
    },
    {
      id: 7,
      title: "Process Indicator",
    },
    {
      id: 8,
      title: "Temperature Controller",
    },
    {
      id: 9,
      title: "Variable Frequency Drive",
    },
    {
      id: 10,
      title: "Level Measurement",
    },
    {
      id: 11,
      title: "Temperature Measurement",
    },
    {
      id: 12,
      title: "Flow Measurement",
    },
    {
      id: 13,
      title: "Automation",
    },
    {
      id: 14,
      title: "Measuring Instruments",
    },
    {
      id: 15,
      title: "Lab Instruments",
    },
    {
      id: 16,
      title: "Scientific Machine",
    },
    {
      id: 17,
      title: "Cold Chain Temperature Measurement",
    },
    {
      id: 18,
      title: "Pressure Transmitters",
    },
  ];

  useEffect(() => {
    console.log(selected);
    switch (selected) {
      case 1:
        setData(LevelSwitch);
        break;
      case 2:
        setData(LevelTransmitter);
        break;
      case 3:
        setData(PLC);
        break;
      case 4:
        setData(PressureMeasurement);
        break;
      case 5:
        setData(PressureSwitch);
        break;
      case 6:
        setData(PressureTransmitter);
        break;
      case 7:
        setData(ProcessIndicator);
        break;
      case 8:
        setData(TemperatureController);
        break;
      case 9:
        setData(VariableFrequencyDrive);
        break;
      case 10:
        setData(LevelMeasurement);
        break;
      case 11:
        setData(TemperatureMeasurement);
        break;
      case 12:
        setData(FlowMeasurement);
        break;
      case 13:
        setData(Automation);
        break;
      case 14:
        setData(MeasuringInstruments);
        break;
      case 15:
        setData(LabInstruments);
        break;
      case 16:
        setData(ScientificMachine);
        break;
      case 17:
        setData(ColdChainTemperatureMeasurement);
        break;
      case 18:
        setData(PressureTransmitters);
        break;
      default:
        setData(LevelSwitch);
    }
  }, [selected]);

  const navigate = useNavigate();
  const handleInquiry = () => {
    navigate("/formpage");
  };

  return (
    <div className="products">
      <MetaData
        title="Products"
        contents={
          "Products are available in Ariston Instruments: Level Switch, Level Transmitter, PLC, Pressure Measurement, Pressure Switch, Pressure Transmitter, Process Indicator, Temperature Controller, Variable Frequency Drive, Level Measurement, Temperature Measurement, Flow Measurement, Automation, Measuring Instruments, Lab Instruments, Scientific Machine, Cold Chain Temperature Measurement, Pressure Transmitters"
        }
      />
      <Navbar />
      <HeaderWave />
      <TaglineProducts />
      {/* ============ Container ============ */}
      <div className="container productContainer">
        {/* ============ Tags ============ */}
        <ul className="TopUl">
          {list.map((item, index) => {
            return (
              <ProductLists
                title={item.title}
                setSelected={setSelected}
                id={item.id}
                key={index}
              />
            );
          })}
        </ul>
        {/* ============ Products List ============ */}
        {data.map((items) => {
          return (
            <div className="productsCard">
              <MetaData title={items.productName} contents={items.desc} />
              <div className="productsImage">
                <img src={items.Image} alt={items.productName} />
              </div>
              <div className="productsContent">
                <h3 className="name">{items.productName}</h3>
                <div className="makeNmodel">
                  <p className="make">
                    Please send Make/Model via inquiry {items.make}
                  </p>
                  <p className="model"> {items.model}</p>
                </div>
                <div className="tagName">{items.productTag}</div>
                <div className="inquiry">
                  <div className="btn1 btn" onClick={handleInquiry}>
                    Send Inquiry
                  </div>
                  {/* <div className="btn2 btn">Detail...</div> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <section className="aboutHeadingSection">
        <p>Get the best Deal for Almost Everything</p>
      </section>
      <Footer />
    </div>
  );
};

export default Products;
