import React from "react";
import "./HeaderWave.css";

const HeaderWave = () => {
  const style1 = {
    transform: `rotate(180deg)`,
    transition: `0.3s`,
  };
  const style2 = {
    transform: `translate(0, 0px)`,
    opacity: `1`,
  };
  return (
    <>
      <svg
        id="wave"
        style={style1}
        viewBox="0 0 1440 490"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="headerWave"
      >
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stop-color="rgba(243, 106, 62, 1)" offset="0%"></stop>
            <stop stop-color="rgba(255, 179, 11, 1)" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path
          style={style2}
          fill="url(#sw-gradient-0)"
          d="M0,196L60,187.8C120,180,240,163,360,163.3C480,163,600,180,720,187.8C840,196,960,196,1080,179.7C1200,163,1320,131,1440,155.2C1560,180,1680,261,1800,318.5C1920,376,2040,408,2160,424.7C2280,441,2400,441,2520,432.8C2640,425,2760,408,2880,408.3C3000,408,3120,425,3240,432.8C3360,441,3480,441,3600,424.7C3720,408,3840,376,3960,326.7C4080,278,4200,212,4320,228.7C4440,245,4560,343,4680,383.8C4800,425,4920,408,5040,334.8C5160,261,5280,131,5400,65.3C5520,0,5640,0,5760,65.3C5880,131,6000,261,6120,261.3C6240,261,6360,131,6480,106.2C6600,82,6720,163,6840,163.3C6960,163,7080,82,7200,49C7320,16,7440,33,7560,98C7680,163,7800,278,7920,334.8C8040,392,8160,392,8280,367.5C8400,343,8520,294,8580,269.5L8640,245L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z"
        ></path>
      </svg>
    </>
  );
};

export default HeaderWave;
