import React from "react";
import "./SocialMediaBTN.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";

const SocialMediaBTN = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div class="social-links">
      <div
        id="instagram"
        className="social-btn flex-center"
        onClick={() => openInNewTab("https://www.instagram.com/aristoninst/")}
      >
        <InstagramIcon className="icon1" />
        <span>@aristoninst</span>
      </div>

      <div
        id="linkedin"
        className="social-btn flex-center"
        onClick={() =>
          openInNewTab("https://www.linkedin.com/company/aristoninst/")
        }
      >
        <LinkedInIcon className="icon2" />
        <span>aristoninst</span>
      </div>

      <div
        id="facebook"
        className="social-btn flex-center"
        onClick={() => openInNewTab("https://www.facebook.com/aristoninst")}
      >
        <FacebookIcon className="icon3" />
        <span>aristoninst</span>
      </div>
    </div>
  );
};

export default SocialMediaBTN;
