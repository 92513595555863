import React from "react";
import "./Buttons.css";

const AboutBTN = () => {
  return (
    <button class="button">
      <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">ABOUT US</span>
      </span>
    </button>
  );
};

export default AboutBTN;
