import React from "react";
import "./OurClient.css";
import pic1 from "../../Assets/Danfoss.jpg";
import pic2 from "../../Assets/DeltaTrak.jpg";
import pic3 from "../../Assets/ElitechTechnology.jpg";
import pic4 from "../../Assets/Gems.jpg";
import pic5 from "../../Assets/Honeywell.jpg";
import pic6 from "../../Assets/Siemens.jpg";
import pic7 from "../../Assets/Wika.jpg";
import pic8 from "../../Assets/Testo.jpg";
import pic9 from "../../Assets/Yokogawa.jpg";

const OurClient = () => {
  const ourPart = [
    {
      Image: pic1,
      name: "Danfoss",
    },
    {
      Image: pic2,
      name: "DeltaTrak",
    },
    {
      Image: pic3,
      name: "Elitech Technology",
    },
    {
      Image: pic4,
      name: "Gems",
    },
    {
      Image: pic5,
      name: "Honeywell",
    },
    {
      Image: pic6,
      name: "Siemens",
    },
    {
      Image: pic7,
      name: "Wika",
    },
    {
      Image: pic8,
      name: "Testo",
    },
    {
      Image: pic9,
      name: "Yokogawa",
    },
  ];
  return (
    <div className="flexCard flexCardOUR">
      {ourPart.map((items) => {
        return (
          <div class="cardour">
            <div class="card-img">
              <img src={items.Image} alt={items.name} />
            </div>
            <div class="card-info">
              <p class="text-title">{items.name}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OurClient;
