import React from "react";
import "./Footer.css";
import AristonLogo from "../../Assets/AristonLogo.png";
import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

const Footer = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div className="footer">
      <div className="left">
        <div className="imageFooter">
          <img src={AristonLogo} alt={"Ariston Instruments Logo"} />
        </div>
        <p>Instruments</p>
      </div>
      <div className="right">
        <Link
          to={"/"}
          className="link"
          onClick={() =>
            openInNewTab("https://www.linkedin.com/company/aristoninst/")
          }
        >
          {<LinkedInIcon />}
        </Link>
        <Link
          to={"/"}
          className="link"
          onClick={() => openInNewTab("https://www.instagram.com/aristoninst/")}
        >
          {<InstagramIcon />}
        </Link>
        <Link
          to={"/"}
          className="link"
          onClick={() => openInNewTab("https://www.facebook.com/aristoninst")}
        >
          {<FacebookIcon />}
        </Link>
      </div>
    </div>
  );
};

export default Footer;
