import React from "react";
import "./ServicesCard.css";

const ServicesCard = () => {
  const serv = [
    {
      title: "Pressure Instruments",
      desc: "Instruments used to measure and display pressure mechanically are called pressure gauges, vacuum gauges or compound gauges (vacuum & pressure).",
    },
    {
      title: "Temperature Instruments",
      desc: "Temperature measurement (also known as thermometry) describes the process of measuring a current local temperature for immediate or later evaluation.",
    },
    {
      title: "Flow Instruments",
      desc: "Flow measurement is an action to measure the flow of liquids, gases and vapors using a flow measurement instrument or device, which measures the rate of flow or the quantity of flow.",
    },
    {
      title: "Level Instruments",
      desc: "Level instruments are a class of instruments designed to display and/or transmit a signal that corresponds to the level of the material that is being measured within a containing vessel.",
    },
    {
      title: "Erection Hardware",
      desc: "Steel buildings and bridges are generally erected with cranes, derricks, or specialized units.",
    },
    {
      title: "Measurement and Accuracy Instruments",
      desc: "The accuracy of instrumentation is determined by the difference of a measured value compared to its actual (true) value.",
    },
    {
      title: "Others Instruments",
      desc: `Whatsapp Us: +91-954-857-6197\n Office Phone: +91-844-576-7527\n Email: aristoninst@gmail.com`,
    },
  ];
  return (
    <div className="flexCard">
      {serv.map((items) => {
        return (
          <div className="card">
            <div className="header">
              <div className="img-box">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    fill="#FB9B1C"
                    d="M20.083 15.2l1.202.721a.5.5 0 0 1 0 .858l-8.77 5.262a1 1 0 0 1-1.03 0l-8.77-5.262a.5.5 0 0 1 0-.858l1.202-.721L12 20.05l8.083-4.85zm0-4.7l1.202.721a.5.5 0 0 1 0 .858L12 17.65l-9.285-5.571a.5.5 0 0 1 0-.858l1.202-.721L12 15.35l8.083-4.85zm-7.569-9.191l8.771 5.262a.5.5 0 0 1 0 .858L12 13 2.715 7.429a.5.5 0 0 1 0-.858l8.77-5.262a1 1 0 0 1 1.03 0zM12 3.332L5.887 7 12 10.668 18.113 7 12 3.332z"
                  ></path>
                </svg>
              </div>
              <h1 className="title">{items.title}</h1>
            </div>
            <div className="content">
              <p>{items.desc}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ServicesCard;
