import React from "react";
import "./Buttons.css";

const BackHome = () => {
  return (
    <button class="button">
      <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">BACK TO HOME</span>
      </span>
    </button>
  );
};

export default BackHome;
