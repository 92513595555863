import React from "react";
import { Link } from "react-router-dom";
import "./ProductLists.css";

function ProductLists({ title, setSelected, id }) {
  return (
    <Link className={"ProductItems"} onClick={() => setSelected(id)}>
      {title}
    </Link>
  );
}

export default ProductLists;
