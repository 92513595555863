import React from "react";
import "./ContactDetail.css";

const ContactDetail = () => {
  return (
    <div className="contactDetail">
      <div className="contactDiv1">
        <h4>Contact</h4>
        <p
          id="mailID"
          onClick={() => (window.location = "mailto:aristoninst@gmail.com")}
        >
          aristoninst@gmail.com
        </p>
      </div>
      <div className="contactDiv2">
        <div className="div1">
          <h4>Address</h4>
          <p>Shri Prakashanand Ji Complex, Gaya Prasad </p>
          <p>Shivpuri, Etah Road, Tundla, UP-283204, INDIA</p>
        </div>
        <div className="div1">
          <h4>Phone</h4>
          <p>Office: +91-954-857-6197</p>
          <p>Support: +91-844-576-7527</p>
        </div>
        <div className="div1">
          <h4>Web</h4>
          <p>www.aristoninst.in</p>
        </div>
      </div>
    </div>
  );
};

export default ContactDetail;
