import React from "react";
import "./FooterWave.css";

const FooterWave = () => {
  const style1 = {
    transform: `rotate(0deg)`,
    transition: `0.3s`,
  };
  const style2 = {
    transform: `translate(0, 0px)`,
    opacity: `1`,
  };
  return (
    <div>
      <svg
        id="wave"
        style={style1}
        viewBox="0 0 1440 490"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="footerWave"
      >
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stop-color="rgba(243, 106, 62, 1)" offset="0%"></stop>
            <stop stop-color="rgba(255, 179, 11, 1)" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path
          style={style2}
          fill="url(#sw-gradient-0)"
          d="M0,98L80,122.5C160,147,320,196,480,179.7C640,163,800,82,960,57.2C1120,33,1280,65,1440,65.3C1600,65,1760,33,1920,81.7C2080,131,2240,261,2400,310.3C2560,359,2720,327,2880,334.8C3040,343,3200,392,3360,351.2C3520,310,3680,180,3840,106.2C4000,33,4160,16,4320,49C4480,82,4640,163,4800,236.8C4960,310,5120,376,5280,383.8C5440,392,5600,343,5760,285.8C5920,229,6080,163,6240,171.5C6400,180,6560,261,6720,261.3C6880,261,7040,180,7200,155.2C7360,131,7520,163,7680,171.5C7840,180,8000,163,8160,196C8320,229,8480,310,8640,343C8800,376,8960,359,9120,302.2C9280,245,9440,147,9600,155.2C9760,163,9920,278,10080,334.8C10240,392,10400,392,10560,343C10720,294,10880,196,11040,171.5C11200,147,11360,196,11440,220.5L11520,245L11520,490L11440,490C11360,490,11200,490,11040,490C10880,490,10720,490,10560,490C10400,490,10240,490,10080,490C9920,490,9760,490,9600,490C9440,490,9280,490,9120,490C8960,490,8800,490,8640,490C8480,490,8320,490,8160,490C8000,490,7840,490,7680,490C7520,490,7360,490,7200,490C7040,490,6880,490,6720,490C6560,490,6400,490,6240,490C6080,490,5920,490,5760,490C5600,490,5440,490,5280,490C5120,490,4960,490,4800,490C4640,490,4480,490,4320,490C4160,490,4000,490,3840,490C3680,490,3520,490,3360,490C3200,490,3040,490,2880,490C2720,490,2560,490,2400,490C2240,490,2080,490,1920,490C1760,490,1600,490,1440,490C1280,490,1120,490,960,490C800,490,640,490,480,490C320,490,160,490,80,490L0,490Z"
        ></path>
      </svg>
    </div>
  );
};

export default FooterWave;
