import React from "react";
import "./Tagline.css";
import AristonLogo from "../../Assets/AristonLogo.png";

const Tagline = () => {
  return (
    <div className="tagLine">
      <div className="herosection">
        <section className="inst">
          <p>INSTRUMENTATION</p>
          <p>&</p>
          <p>CONTROL</p>
        </section>
        <div className="imageDiv">
          <div className="imgBox">
            <img src={AristonLogo} alt="Ariston Instruments" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tagline;
