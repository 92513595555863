import React from "react";
import "./Blog.css";
import Navbar from "../../Utility/Navbar/Navbar";
import HeaderWave from "../../Utility/Wave/HeaderWave";
import TaglineBlogs from "../../Utility/Tagline/TaglineBlogs";
import Footer from "../../Utility/Footer/Footer";
import MetaData from "../../Utility/MetaData";

const Blog = () => {
  return (
    <div className="blogs">
      <MetaData title="Blogs" contents={""} />
      <Navbar />
      <HeaderWave />
      <TaglineBlogs />
      <section className="aboutHeadingSection">
        <p>No Blogs Yet</p>
      </section>
      <Footer />
    </div>
  );
};

export default Blog;
