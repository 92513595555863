import React from "react";
import "./About.css";
import Navbar from "../../Utility/Navbar/Navbar";
import HeaderWave from "../../Utility/Wave/HeaderWave";
import TaglineAbout from "../../Utility/Tagline/TaglineAbout";
import Footer from "../../Utility/Footer/Footer";
import AristonLogo from "../../Assets/AristonLogo.png";
import SocialMediaBTN from "../../Utility/SocialMediaBTN/SocialMediaBTN";
import TextMe from "../../Utility/TextMe/TextMe";
import ARISTON from "../../Assets/ARISTON.png";
import ContactDetail from "../../Utility/ContactDetail/ContactDetail";
import FooterWave from "../../Utility/Wave/FooterWave";
import MetaData from "../../Utility/MetaData";

const About = () => {
  return (
    <div className="about">
      <MetaData title="About Us" contents={"Established in the year 2021 at Tundla (Uttar Pradesh), We,“Ariston Instruments” are one of the leading Retail Trader of a premium range of Pressure Measurement, Ac Drive & Temperature Controller, many more."} />
      <Navbar />
      <HeaderWave />
      <TaglineAbout />
      <div className="container aboutcontainer">
        {/* ============ About Us ============ */}
        <div className="span3 span"></div>
        <div className="aboutSection innerContainer">
          <div className="leftAbout">
            <div className="imageDivAbout">
              <img src={AristonLogo} alt={"ariston instruments logo"} />
            </div>
          </div>
          <div className="rightAbout">
            <div className="aboutDiv1">
              <p className="title">ABOUT US</p>
              <h1>About Ariston Instruments</h1>
            </div>
            <div className="aboutDiv2">
              <p>
                Established in the year 2021 at Tundla (Uttar Pradesh),
                We,“Ariston Instruments” are one of the leading Retail Trader of
                a premium range of Pressure Measurement, Ac Drive & Temperature
                Controller, many more.
              </p>
              <p>
                With the support and guidance of our mentor Mr. Sachin Kumar, we
                have created a niche for ourselves in the market and
                successfully established beneficial business ties with the most
                reputed vendors and clients in the industry.
              </p>
            </div>
            {/* ============ Contact Details ============ */}
            <div className="contactSection innerContainer">
              <h2>Keep in Touch</h2>
              <div className="span1 span"></div>
              <TextMe />
              <SocialMediaBTN />
            </div>
          </div>
        </div>
      </div>
      <section className="aboutHeadingSection">
        <p>Founder - SACHIN KUMAR (Ariston Instruments)</p>
      </section>
      <section className="logoSection">
        <div className="divLogoSection">
          <img src={ARISTON} alt="ARISTON LOGO" />
        </div>
      </section>
      <section className="contactDetailSection">
        <ContactDetail />
      </section>
      <FooterWave />
      <Footer />
    </div>
  );
};

export default About;
