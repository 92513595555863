import React from "react";
import "./IndustryServe.css";

const IndustryServe = () => {
  const industry = [
    {
      name: "Aluminum",
    },
    {
      name: "Automobile",
    },
    {
      name: "Breweries",
    },
    {
      name: "Cement",
    },
    {
      name: "Chemical Plants",
    },
    {
      name: "Electrical",
    },
    {
      name: "Electronic",
    },
    {
      name: "Fertilizer",
    },
    {
      name: "Food & Beverage",
    },
    {
      name: "Furnace",
    },
    {
      name: "Oil",
    },
    {
      name: "Paper",
    },
    {
      name: "Pharmaceutical",
    },
    {
      name: "Power Plants",
    },
    {
      name: "Rubber",
    },
    {
      name: "SPM Machine Manufacturer",
    },
    {
      name: "Steel",
    },
    {
      name: "Sugar",
    },
    {
      name: "Textile",
    },
    {
      name: "Tyre",
    },
    {
      name: "Water Treatment",
    },
  ];
  return (
    <div className="flexCard">
      {industry.map((items) => {
        return <button class="buttonq"> {items.name}</button>;
      })}
    </div>
  );
};

export default IndustryServe;
