import React from "react";
import "./Tagline.css";
import "./TaglineServices.css"

const TaglineServices = () => {
  return (
    <div className="tagLine tagLineServices">
      <div className="herosection serviceshero">
        <section className="inst">
            <p className="ourservices">OUR</p>
          <p>SERVICES</p>
        </section>
      </div>
    </div>
  );
};

export default TaglineServices;
