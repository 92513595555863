import React from "react";
import "./ScrollBTN.css";

const ScrollBTN = () => {
  const style1 = {
    color: "skyblue",
  };
  return (
    <div className="ScrollBTN">
      <div className="scrolldown" style={style1}>
        <div className="chevrons">
          <div className="chevrondown"></div>
          <div className="chevrondown"></div>
        </div>
      </div>
    </div>
  );
};

export default ScrollBTN;
