import React from "react";
import "./TeamMembers.css";
import sachinpic from "../../Assets/SACHIN KUMAR.jpg";
import rakeshpic from "../../Assets/RAKESH KUMAR.jpg";
import hemltatapic from "../../Assets/Hemlata Nishad.jpg";
import TtyIcon from "@mui/icons-material/Tty";

const TeamMembers = () => {
  const team = [
    {
      image: sachinpic,
      name: "Sachin Kumar",
      designation: "Sales Engineer",
      testimonial: {
        mob: "(+91)8445767527",
        mail: "aristoninst@gmail.com",
      },
    },
    // {
    //   image: rakeshpic,
    //   name: "Rakesh Kumar",
    //   designation: "Assistant Manager Sales",
    //   testimonial: {
    //     mob: "(+91)9520718448",
    //     mail: "rkn1996dec@gmail.com",
    //   },
    // },
    {
      image: hemltatapic,
      name: "Hemlata Nishad",
      designation: "Sales Engineer",
      testimonial: {
        mob: "(+91)9548576197",
        mail: "schnkkr9@gmail.com",
      },
    },
  ];

  const wh = {
    width: "30px",
    margin: 20,
    color: "#414141",
  };
  return (
    <div class="container1">
      <div class="row">
        {team.map((items) => {
          return (
            <div class="col-lg-4">
              <div class="card11">
                <div class="face front-face">
                  <img src={items.image} alt={items.name} class="profile" />
                  <div class="pt-3 text-uppercase name">{items.name}</div>
                  <div class="designation">{items.designation}</div>
                </div>
                <div class="face back-face">
                  <span class="fas fa-quote-left"></span>
                  <div class="testimonial">
                    <TtyIcon style={wh} />
                    <p className="Mobile786">{items.testimonial.mob}</p>
                    <p className="Mobile786">{items.testimonial.mail}</p>
                  </div>
                  <span class="fas fa-quote-right"></span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TeamMembers;
