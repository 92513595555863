import React from "react";
import "./Buttons.css";

const Buttons = () => {
  return (
    <button class="button">
      <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">Read More . . .</span>
      </span>
    </button>
  );
};

export default Buttons;
