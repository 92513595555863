import React from "react";
import "./Contact.css";
import Navbar from "../../Utility/Navbar/Navbar";
import HeaderWave from "../../Utility/Wave/HeaderWave";
import TaglineContact from "../../Utility/Tagline/TaglineContact";
import Footer from "../../Utility/Footer/Footer";
import MetaData from "../../Utility/MetaData";

const Contact = () => {
  return (
    <div className="contact">
       <MetaData title="Contact Us" contents={"Address: Shri Prakashanand Ji Complex, Gaya Prasad Shivpuri, Etah Road, Tundla, UP-283204, INDIA, Email: aristoninst@gmail.com, Phone: +91-954-857-6197, +91-844-576-7527"}/>
      <Navbar />
      <HeaderWave />
      <TaglineContact />
      {/* ============ Container ============ */}
      <div className="container contactcontainer">
        <div className="contactdiv">
          <div className="location contactdiv1">
            <p className="contactTitle1">LOCATION</p>
            <h1>Office</h1>
            <div className="conP">
              <p>
                Shri Prakashanand Ji Complex, Gaya Prasad Shivpuri, Etah Road,
                Tundla, UP-283204, INDIA
              </p>
            </div>
          </div>
          <div className="contactmail contactdiv1">
            <p className="contactTitle1">CONTACT</p>
            <h1>Email</h1>
            <div className="conP">
              <p>aristoninst@gmail.com</p>
            </div>
          </div>
          <div className="call contactdiv1">
            <p className="contactTitle1">CALL</p>
            <h1>Phone</h1>
            <div className="conP">
              <p>+91-954-857-6197</p>
              <p>+91-844-576-7527</p>
            </div>
          </div>
        </div>
      </div>
      <section className="aboutHeadingSection">
        <p>Keep in Touch</p>
      </section>

      <Footer />
    </div>
  );
};

export default Contact;
